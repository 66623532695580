/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { countryList } from "../lib/utils"
import { currencyTypeOptions,currencyTypeOptionsSymbols, languageOptions } from "../components/SearchResults/SearchFilters/filterOptions"
// import { usePropertyStore } from "../components/SearchResults/store/propertyStore"
const initialState = {
  currencyType:
    (typeof window !== "undefined" &&
      localStorage.getItem("currency") === "object" &&
      localStorage.getItem("currency") != null) ||
    (typeof window !== "undefined" && !!localStorage.getItem("currency"))
      ? JSON.parse(localStorage.getItem("currency"))
      : { value: "AED", label: "UAE Dirams - AED د.إ" },
      currencyTypeOptionsSymbols,
  language: (typeof window !== "undefined" &&
  localStorage.getItem("language") === "object" &&
  localStorage.getItem("language") != null) ||
(typeof window !== "undefined" && !!localStorage.getItem("language"))
  ? JSON.parse(localStorage.getItem("language"))
  :{ value: "EN", label: "English" },
  languageOptions,
  sizeType:
    (typeof window !== "undefined" &&
      localStorage.getItem("sizeType") != null) ||
    (typeof window !== "undefined" && !!localStorage.getItem("sizeType"))
      ? localStorage.getItem("sizeType")
      : "mToFt"
}
export const useGlobalStore = create(
  devtools(
    immer((set) => ({
      gclid: "",
      targetid: "",
      fbclid: "",
      leadSource: "30628",
      ipAddress: "",
      countryName: "",
      cityName: "",
      stateName: "",
      countryFullName: "",
      utmMedium: "",
      utmCampaignId: "",
      utmTerm: "",
      countryCode: "",
      spotlight: {},
      sidebarProject: {},
      gaEmail: "",
      utm_keyword: "",
      utm_platform: "",
      showSuccessMsg: false,
      queryParams: "",
      ...initialState,
      setGclid: (gclid) =>
        set((state) => {
          state.gclid = gclid
        }),
        setTargetid: (targetid) =>
          set((state) => {
            state.targetid = targetid
          }),
        setQueryParams: (queryParams) =>
          set((state) => {
            state.queryParams = queryParams
          }),
        setShowSuccessMsg: (showSuccessMsg) =>
          set((state) => {
            state.showSuccessMsg = showSuccessMsg
          }),
        setgaEmail: (gaEmail) =>
          set((state) => {
            state.gaEmail = gaEmail
          }),
      setFbclid: (fbclid) =>
        set((state) => {
          state.fbclid = fbclid
        }),
        setSpotlight: (spotlight) => 
          set((state) => {
            state.spotlight = spotlight
          }),
          setSidebarProject: (sidebarProject) => 
            set((state) => {
              state.sidebarProject = sidebarProject
            }),
          
      setIpAddress: (ipAddress) =>
        set((state) => {
          state.ipAddress = ipAddress
        }),
        setCityName: (cityName) =>
          set((state) => {
            state.cityName = cityName
          }),
          setStateName: (stateName) =>
            set((state) => {
              state.stateName = stateName
            }),
            setStateCountryFullName: (countryFullName) =>
              set((state) => {
                state.countryFullName = countryFullName
              }),
            
      setCountry: (countryName) =>
        set((state) => {
          const id = countryList.find(
            (item) =>
              item.name?.toLowerCase() === countryName?.toLowerCase() ||
              item.name?.toLowerCase()?.includes(countryName) ||
              countryName?.toLowerCase()?.includes(item.name)
          )?.id

          state.countryName = id || countryName
        }),
      setLeadSource: (leadSource) =>
        set((state) => {
          state.leadSource = leadSource
        }),
        setUtmKeyword: (utm_keyword) =>
          set((state) => {
            state.utm_keyword = utm_keyword
          }),
          setUtmPlatform: (utm_platform) =>
            set((state) => {
              state.utm_platform = utm_platform
            }),
      setUtmMedium: (utmMedium) =>
        set((state) => {
          state.utmMedium = utmMedium
        }),
        setCountryCode: (countryCode) =>
          set((state) => {
            state.countryCode = countryCode
          }),
      setUtmCampaignId: (utmCampaignId) =>
        set((state) => {
          state.utmCampaignId = utmCampaignId
        }),
      setUtmTerm: (utmTerm) =>
        set((state) => {
          state.utmTerm = utmTerm
        }),
        setSizeType: (sizeType) =>
          set((state) => {
            state.sizeType = sizeType
          }),
      setCurrencyType: (currencyType) =>
        set((state) => {
          state.currencyType = currencyType
          // usePropertyStore.setState((pre) => ({
          //   ...pre,
          // }))
        }),
      setCurrencyTypeOptions: (currencyTypeOptionsProps) =>
        set((state) => {
          state.currencyTypeOptionsSymbols = currencyTypeOptionsProps
        }),
        setLanguage: (language) =>
        set((state) => {
          state.language = language
        }),
      setlanguageOptions: (languageOptionsProps) =>
        set((state) => {
          state.languageOptions = languageOptionsProps
        }),
    }))
  )
)
